import React, {useContext, useEffect} from 'react';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Context} from '../../AppContext';
import {Heading} from '../../Widgets';
import {navigate} from 'gatsby';
import queryString from 'query-string';

export default function Validation({location}) {
  const app = useContext(Context);

  useEffect(() => {
    app.actions.setLoading(true);
  }, [app.actions]);

  useEffect(() => {
    try {
      const parsed = queryString.parse(location.search);
      let access_token = parsed.access_token; //validation_token
      if (access_token) {
        navigate(`/register?access_token=${access_token}`);
      } else {
        alert('無法取得認證，請通知客服人員');
      }
    } catch (ex) {
      console.warn(ex);
    }
  }, [location]);

  return (
    <ContentWrapper>
      <div
        style={{
          maxWidth: 600,
          minHeight: 300,
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Heading>正在跳轉至註冊頁...</Heading>
      </div>
    </ContentWrapper>
  );
}
